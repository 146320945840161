module.exports = [{
      plugin: require('/Users/Serene/Desktop/Programming/porfolio/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Serene/Desktop/Programming/porfolio/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/SR_Circle.png"},
    },{
      plugin: require('/Users/Serene/Desktop/Programming/porfolio/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Karla","Bitter"]}},
    },{
      plugin: require('/Users/Serene/Desktop/Programming/porfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
